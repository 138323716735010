<template>
  <ModalPortal>
    <ModalOverlay @on-close="if (!preventClickOutside) emit('onClose');">
      <slot></slot>
    </ModalOverlay>
  </ModalPortal>
</template>

<script setup lang="ts">
const emit = defineEmits<{ onClose: [] }>();
defineProps<{
  preventClickOutside?: boolean;
}>();
</script>
